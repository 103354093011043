import React, { useContext } from 'react';
import context from '../../context';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import font1 from  '/src/images/1.jpg';
import font2 from  '/src/images/2.jpg';
import font3 from  '/src/images/3.jpg';
import { Section } from '../../styled-components';

const SectionCustom = styled(Section)`

`
const Image = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  object-position: center;
  @media(min-width: 768px){
    height: 100%;
  }
`
const InfoCont = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Title = styled.h2`
  //width: 50%;
  color: ${props => props.theme.primaryColor};
  margin: 2rem 0;
`
const Description = styled.div`
text-align: justify;
text-align-last: justify;
`

export default ()=> {
  const state = useContext(context);
  return(
    <SectionCustom>
      <Container>
        <Row>
          <Col xs={12} md={{ span: 5, order: 2 }}>
            <InfoCont>
            <Title>
              Plan Referido:
            </Title>
            <Description dangerouslySetInnerHTML={{ __html: "Consiste en captar el dato de una propiedad disponible para incluirla en el catálogo de Nova Home: éste sería el único paso del plan. A través de la obtención del dato, Nova Home comenzará a trabajar sobre dicha propiedad Sólo por ofrecer el dato de una propiedad para la venta, se puede obtener beneficios en la comisión." }} />
            </InfoCont>
          </Col>          
          <Col xs={12} md={{ span: 7, order: 1}}>
            <Image src={font1} />
          </Col>
        </Row> 
        <br/>
        <Row>
          <Col xs={12} md={{ span: 7, order: 2 }}>
          <Image src={font2} />
          </Col>
          <Col xs={12} md={{ span: 5, order: 1 }}>
            <InfoCont>
            <Title>
            Plan Nova Home: 
            </Title>
            <Description dangerouslySetInnerHTML={{ __html: "Empieza tu carrera de asesor inmobiliario, trabaja directamente con Nova-Home. Para ello, es necesario contar con una propiedad apta para la venta. A cambio, Nova-Home ofrece infinidad de oportunidades para la adecuada venta de la propiedad: Apoyo en cada una de las gestiones, publicaciones en más de 22 portales inmobiliarios, Flyers personalizados y de captación, redes sociales, tasación, publicidad, asesoría legal y comercial para el cliente, obtención de los documentos necesarios para la venta de dicha propiedad, así como ayuda con el cierre y con los prospectos de posibles compradores. Serás el nexo entre Nova-Home y el dueño para poder realizar una buena gestión de venta sobre la propiedad: Negociaciones, mostrar la propiedad, realizar el cierre del negocio, así como atender adecuadamente a los posibles compradores que Nova Home envíe." }} />
            </InfoCont>
          </Col>          
        </Row> 
        <br/>
        <Row>
          <Col xs={12} md={{ span: 7, order: 1 }}>
          <Image src={font3} />
          </Col>
          <Col xs={12} md={{ span: 5, order: 2 }}>
            <InfoCont>
            <Title>
            Plan Canje: 
            </Title>
            <Description dangerouslySetInnerHTML={{ __html: "Consiste en trabajar de manera conjunta con Nova-Home. Para ello, el trato es directo con un Corredor de Propiedades dado de alta y contar con alguna propiedad sobre la que los clientes de Nova-Home han mostrado su interés. " }} />
            </InfoCont>
          </Col>          
        </Row> 
      </Container>
    </SectionCustom>
  )
}